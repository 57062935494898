<template>
  <ak-container class="ak-card-table" card :bodyStyle="{padding: 0}">
    <a-table ref="table" :loading="loading" rowKey="id" :columns="columns" :data-source="tableList" style="width:96%;margin:30px auto">
      <!-- 操作 -->
      <template #action="text, record">
        <a @click="clickEdit(record)">
          编辑
        </a>
      </template>
    </a-table>
  </ak-container>
</template>

<script>
import * as http from '@/api/set/http.api'

const columns = [{
  title: 'ID',
  dataIndex: 'id'
}, {
  title: '平台协议名称',
  dataIndex: 'agreementName',
}, {
  title: '操作',
  dataIndex: 'action',
  scopedSlots: { customRender: 'action' }
}]

export default {
  data () {
    return {
      loading: false,
      queryFilters: {},
      columns,
      tableList: []
    }
  },
  created(){
    this.queryData()
  },
  methods: {
    // 加载数据方法
    queryData () {
      this.loading = true
      http.getPlatformHttp({...this.queryFilters})
        .then((res) => {
          if (res.code === 0){
            this.tableList = res.data
            this.pagination = res.data.total
          }
          this.loading = false
        })
        .catch(e => {
          this.message = e.message || '查询错误'
        })
        .finally(() => {
          this.loading = false
        })
    },
    clickEdit(record){
      localStorage.setItem("yyd_plat_type", JSON.stringify(record));
      let url = "/set/platformHttp/edit";
      this.$router.push(url || '/')
    }
  }
}
</script>
