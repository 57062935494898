import request from '@/utils/request'

const baseUrl = `${process.env.VUE_APP_API_API}/admin`;
/**
 * 平台类型列表
 */
export function getPlatformType (params) {
  return request({
    method: 'get',
    url: `${baseUrl}/platform_type/platformType`,
    params
  })
}

/**
 * 平台协议列表
 */
export function getPlatformHttp (params) {
  return request({
    method: 'get',
    url: `${baseUrl}/platform-agreement/agreement`,
    params
  })
}

/**
 * 平台协议编辑
 */
export function editPlatformHttp (data) {
  return request({
    method: 'post',
    url: `${baseUrl}/platform-agreement/edit-platform-agreement`,
    data
  })
}
